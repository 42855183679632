.dialog-rainbow-border .MuiPaper-root {
    position: relative;
    border: none !important;
    overflow: visible !important;
  }
  
  .dialog-rainbow-border .MuiPaper-root::before {
    content: '';
    position: absolute;
    inset: -4px;  /* This creates the 2px border */
    background: linear-gradient(
      45deg,
      #2171d3,    /* Primary blue */
    #4a90e2,    /* Lighter blue */
    #6ba6e8,    /* Even lighter blue */
    #4a90e2,    /* Back to lighter blue */
    #2171d3     /* Back to primary blue */
    );
    border-radius: inherit;
    padding: 4px;
    z-index: -1;
    animation: animatedBorder 10s linear infinite;
    background-size: 400%;
    -webkit-mask: 
       linear-gradient(#fff 0 0) content-box, 
       linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
  
  @keyframes animatedBorder {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }