
.stepIcon {
  fill: #3c9ee5;
  font-family: 'Font Awesome 5 Pro';
  font-size: 0.8em;
  text-anchor: middle;
  font-weight: 200;
}

.stepIconEnded {
  fill: darkgray;
  font-family: 'Font Awesome 5 Pro';
  font-size: 0.8em;
  text-anchor: middle;
  font-weight: 200;
}

.stepIconPending {
  fill: green;
  font-family: 'Font Awesome 5 Pro';
  font-size: 0.8em;
  text-anchor: middle;
  font-weight: 200;
}

.node {
  /* height: 135px; */
  /* padding: 2px; */
  width: 180px;
  box-shadow: 6px 6px 18px rgb(197, 194, 194);
  cursor: pointer;
  border-radius: 4px;
  padding:8px 2px;
}

.fullnode {
  height: 220px;
  width: 200px;
  box-shadow: 6px 6px 18px rgb(197, 194, 194);
  border-radius: 15px;
  cursor: inherit;
}

.node-container {
  font-family: Roboto, sans-serif;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  text-align: left;
  height: 100%;
  background-color: white;
  border-radius: 15px;
}

.node-status {
  text-align: center;
  font-size: x-small;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.node-status-preparation {
  background-color: #6D757A;
  color: white;
}

.node-status-integration {
  background-color: orange;
  color: white;
}

.node-status-execution {
  background-color: green;
  color: white;
}